import Vue from 'vue';

const firebaseConfig = {
    apiKey: 'AIzaSyBI4snPcSeb4dDPH6JFgsHW6p6FOcfPigQ',
    authDomain: 'full-well-packaging-company.firebaseapp.com',
    databaseURL: 'https://full-well-packaging-company-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'full-well-packaging-company',
    storageBucket: 'full-well-packaging-company.appspot.com',
    messagingSenderId: '284230744341',
    appId: '1:284230744341:web:1323ed6edbaa19456e511c',
};

Vue.mixin({
    data: function () {
        return {
            get companyName() {
                return 'Full Well Packaging Company Limited'; //公司名稱
            },
            get regFormDomain() {
                return 'https://fullwellpackaging.xyz/';
            },
        };
    },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = '#e8f2fa';

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = '#000';

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
